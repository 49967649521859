import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_REFUELING,
  DELETE_REFUELING,
  REFUELING_FORM_TOOGLE_LOADING,
  SET_REFUELING_LIST,
  SHOW_NOTIFICATION,
  UPDATE_REFUELING,
} from "../constants";
import { formatRefuelings } from "./settingsActionsUtils";

/* REFUELING LIST */
export const fetchRefuelings = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.refuelings.list)
      .then((response) => {
        const refuelings = formatRefuelings(response.data);
        dispatch({
          type: SET_REFUELING_LIST,
          payload: keyBy(refuelings, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// REFUELINGS
export const createRefueling = (newRefueling) => {
  return async (dispatch) => {
    dispatch({ type: REFUELING_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.refuelings.create, newRefueling)
      .then((response) => {
        const refueling = formatRefuelings(response.data);
        dispatch({ type: ADD_NEW_REFUELING, payload: refueling });
        dispatch({ type: REFUELING_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Repostaje creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: REFUELING_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateRefueling = (updatedRefueling) => {
  return async (dispatch) => {
    dispatch({ type: REFUELING_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.refuelings.edit}${updatedRefueling?._id}`, updatedRefueling)
      .then((response) => {
        const refueling = formatRefuelings(response.data);
        dispatch({ type: UPDATE_REFUELING, payload: refueling });
        dispatch({ type: REFUELING_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Repostaje actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: REFUELING_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteRefuelings = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: REFUELING_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.refuelings.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_REFUELING, payload: Ids });
        dispatch({ type: REFUELING_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Repostaje eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: REFUELING_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
