import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_ADVISER,
  DELETE_ADVISER,
  ADVISER_FORM_TOOGLE_LOADING,
  SET_ADVISER_LIST,
  SHOW_NOTIFICATION,
  UPDATE_ADVISER,
} from "../constants";
import { formatAdvisers } from "./settingsActionsUtils";

/* ADVISER LIST */
export const fetchAdvisers = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.advisers.list)
      .then((response) => {
        const advisers = formatAdvisers(response.data);
        dispatch({
          type: SET_ADVISER_LIST,
          payload: keyBy(advisers, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// ADVISERS
export const createAdviser = (newAdviser) => {
  return async (dispatch) => {
    dispatch({ type: ADVISER_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.advisers.create, newAdviser)
      .then((response) => {
        const adviser = formatAdvisers(response.data);
        dispatch({ type: ADD_NEW_ADVISER, payload: adviser });
        dispatch({ type: ADVISER_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Asesor creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: ADVISER_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateAdviser = (updatedAdviser) => {
  return async (dispatch) => {
    dispatch({ type: ADVISER_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.advisers.edit}${updatedAdviser?._id}`, updatedAdviser)
      .then((response) => {
        const adviser = formatAdvisers(response.data);
        dispatch({ type: UPDATE_ADVISER, payload: adviser });
        dispatch({ type: ADVISER_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Asesor actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: ADVISER_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteAdvisers = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: ADVISER_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.advisers.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_ADVISER, payload: Ids });
        dispatch({ type: ADVISER_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Asesor eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: ADVISER_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
