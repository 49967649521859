import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_JOBTYPE,
    DELETE_JOBTYPE,
    JOBTYPE_FORM_TOOGLE_LOADING,
    SET_JOBTYPE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_JOBTYPE,
} from "../constants";
import { formatJobtypes } from "./settingsActionsUtils";

/* JOBTYPE LIST */
export const fetchJobtypes = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.jobtypes.list)
            .then((response) => {
                const jobtypes = formatJobtypes(response.data);
                dispatch({
                    type: SET_JOBTYPE_LIST,
                    payload: keyBy(jobtypes, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// JOBTYPES
export const createJobtype = (newJobtype) => {
    return async (dispatch) => {
        dispatch({ type: JOBTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.jobtypes.create, newJobtype)
            .then((response) => {
                const jobtype = formatJobtypes(response.data);
                dispatch({ type: ADD_NEW_JOBTYPE, payload: jobtype });
                dispatch({ type: JOBTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Puesto de trabajo creado con éxito.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: JOBTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateJobtype = (updatedJobtype) => {
    return async (dispatch) => {
        dispatch({ type: JOBTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.jobtypes.edit}${updatedJobtype?._id}`, updatedJobtype)
            .then((response) => {
                const jobtype = formatJobtypes(response.data);
                dispatch({ type: UPDATE_JOBTYPE, payload: jobtype });
                dispatch({ type: JOBTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Puesto de trabajo actualizado.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: JOBTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteJobtypes = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: JOBTYPE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.jobtypes.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_JOBTYPE, payload: Ids });
                dispatch({ type: JOBTYPE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Puesto de trabajo eliminado.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: JOBTYPE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
