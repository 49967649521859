import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_PLOT,
  DELETE_PLOT,
  PLOT_FORM_TOOGLE_LOADING,
  SET_PLOT_LIST,
  SHOW_NOTIFICATION,
  UPDATE_PLOT,
} from "../constants";
import { formatPlots } from "./settingsActionsUtils";

/* PLOT LIST */
export const fetchPlots = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.plots.list)
      .then((response) => {
        const plots = formatPlots(response.data);
        dispatch({
          type: SET_PLOT_LIST,
          payload: keyBy(plots, "_id"),
        });
        return plots;
      })
      .catch((err) => err);
    return response;
  };
};

// PLOTS
export const createPlot = (newPlot) => {
  return async (dispatch) => {
    dispatch({ type: PLOT_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.plots.create, newPlot)
      .then((response) => {
        const plot = formatPlots(response.data);
        dispatch({ type: ADD_NEW_PLOT, payload: plot });
        dispatch({ type: PLOT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Parcela creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PLOT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updatePlot = (updatedPlot) => {
  return async (dispatch) => {
    dispatch({ type: PLOT_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.plots.edit}${updatedPlot?._id}`, updatedPlot)
      .then((response) => {
        const plot = formatPlots(response.data);
        dispatch({ type: UPDATE_PLOT, payload: plot });
        dispatch({ type: PLOT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Parcela actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PLOT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deletePlots = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: PLOT_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.plots.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_PLOT, payload: Ids });
        dispatch({ type: PLOT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Parcela eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PLOT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
