import {
  CheckCircleTwoTone,
  WarningTwoTone,
  CalendarOutlined,
  CaretRightOutlined,
  CaretLeftOutlined,
  LinkOutlined,
  MailTwoTone,
  ClockCircleOutlined,
  GlobalOutlined,
  CloseCircleTwoTone,
  PlusCircleTwoTone,
  EditOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  DeleteOutlined,
  BarsOutlined,
  PaperClipOutlined,
  SaveTwoTone,
  SyncOutlined,
  CheckCircleOutlined,
  RollbackOutlined,
  WarningFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import AppPlusIcon from "../assets/app/plusIcon";
import colors from "./../constants/colors";

// FONTAWESOME
export const HomeIcon = () => <i className="fad fa-home fa-swap-opacity"></i>;
export const ProfileIcon = () => (
  <i className="fad fa-user-circle fa-swap-opacity"></i>
);
export const UsersIcon = () => (
  <i className="fad fa-poll-people fa-swap-opacity"></i>
);
export const SettingsIcon = () => (
  <i className="fad fa-cog fa-swap-opacity"></i>
);
export const SearchIcon = () => (
  <i className="fad fa-search fa-swap-opacity"></i>
);
export const MenuIcon = ({ isCollapsed }) => (
  <i
    className="fas fa-bars"
    style={{ color: isCollapsed && colors.scheme.light }}
  ></i>
);
export const ToolbarMainIcon = () => <AppPlusIcon />;
export const LogInIcon = () => (
  <i
    className="fad fa-sign-in"
    style={{ color: colors.scheme.main, marginRight: 5 }}
  ></i>
);
export const LogOutIcon = () => <i className="fad fa-sign-out"></i>;
export const GoBackIcon = () => <i className="far fa-chevron-circle-left"></i>;

//  ANTD
export const ClipperIcon = () => <PaperClipOutlined />;
export const CheckIconX = () => (
  <CheckCircleTwoTone twoToneColor={colors.scheme["color-success-500"]} />
);
export const WarningIcon = () => (
  <WarningFilled style={{ color: colors.scheme["color-warning-500"] }} />
);
export const WarningIconX = () => (
  <WarningTwoTone twoToneColor={colors.scheme["color-warning-500"]} />
);
export const LinkIcon = () => <LinkOutlined />;
export const MailIconX = () => <MailTwoTone />;
export const ClockIcon = () => <ClockCircleOutlined />;
export const CalendarIcon = () => <CalendarOutlined />;
export const WorldClockIcon = () => <GlobalOutlined />;
export const RightArrowIcon = () => <CaretRightOutlined />;
export const LeftArrowIcon = () => <CaretLeftOutlined />;
export const CloseIconX = () => (
  <CloseCircleTwoTone twoToneColor={colors.scheme["color-danger-500"]} />
);
export const PlusIcon = () => <PlusCircleOutlined />;
export const PlusIconX = () => (
  <PlusCircleTwoTone twoToneColor={colors.scheme.main} />
);
export const EditIcon = () => <EditOutlined />;
export const SaveIcon = () => <SaveOutlined />;
export const SaveIconX = () => (
  <SaveTwoTone twoToneColor={colors.scheme.main} />
);
export const DeleteIcon = () => <DeleteOutlined />;
export const RestoreIcon = () => <RollbackOutlined />;
export const ResumeIcon = () => <BarsOutlined />;
export const QuestionIcon = () => <QuestionCircleOutlined />;

// CUSTOM
export const InProcessTaskIcon = () => <SyncOutlined />;
export const InProcessSpinTaskIcon = () => <SyncOutlined />;
export const PendingTaskIcon = () => (
  <ClockCircleOutlined />
);
export const FinishedTaskIcon = () => (
  <CheckCircleOutlined />
);

export const CategoryInputIcon = () => <i className="fad fa-tags"></i>;
export const CheckListIcon = ({ colored, margin }) => (
  <i
    className="fad fa-clipboard-check"
    style={{
      color: colored ? colors.scheme.main : "unset",
      marginRight: margin ? 5 : "unset",
    }}
  ></i>
);
export const NewChecklistIcon = () => (
  <i
    className="fad fa-clipboard-check"
    style={{ color: colors.scheme["color-success-600"] }}
  ></i>
);
export const ServicesInputIcon = () => <i className="fad fa-boxes-alt"></i>;
export const NewTaskIcon = () => (
  <i
    className="fad fa-tasks"
    style={{ color: colors.scheme["color-success-600"] }}
  ></i>
);
export const ProjectIcon = () => <i className="fad fa-project-diagram"></i>;
export const DepartmentIcon = () => <i className="fad fa-building"></i>;
export const NewProjectIcon = () => (
  <i
    className="fad fa-project-diagram"
    style={{ color: colors.scheme["color-success-600"] }}
  ></i>
);
export const RepeatIcon = () => <i className="fal fa-repeat"></i>;
export const ReportsIcon = () => <i className="fad fa-file-chart-line"></i>;
export const CalendarMenuIcon = () => <i className="far fa-calendar-alt"></i>;
export const ChatMenuIcon = () => <i className="far fa-comment-alt"></i>;
