import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import {
    ADD_NEW_HOURS,
    DELETE_HOURS,
    HOURS_FORM_TOOGLE_LOADING,
    SET_HOURS,
    SHOW_NOTIFICATION,
    UPDATE_HOURS,
} from "../constants";
import moment from "moment";
import "moment/locale/es";
import { keyBy } from "lodash";
import { formatHours } from "./settingsActionsUtils";
moment().locale("es");

/* HOURS */
export const fetchHours = () => {
    return (dispatch) => {
        axios
            .get(API.hours.list)
            .then((response) => {
                const hours = formatHours(response.data);
                dispatch({ type: SET_HOURS, payload: keyBy(hours, "_id") });
            })
            .catch((err) => err);
    };
};

export const createHours = (newData) => {
    return async (dispatch) => {
        dispatch({ type: HOURS_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.hours.create, newData)
            .then((response) => {
                const hours = formatHours(response.data);
                dispatch({ type: ADD_NEW_HOURS, payload: hours });
                dispatch({ type: HOURS_FORM_TOOGLE_LOADING });
                return response.status;
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: HOURS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateHours = (updated) => {
    return async (dispatch) => {
        const response = await axios
            .put(`${API.hours.edit}${updated?._id}`, updated)
            .then((response) => {
                const hours = formatHours(response.data);
                dispatch({ type: UPDATE_HOURS, payload: hours });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Imputación actualizada.",
                    },
                });
                return response.status;
            })
            .catch((err) => console.log(err));
        return response;
    };
};

export const deleteHours = (Id) => {
    return (dispatch) => {
        axios
            .delete(`${API.hours.delete}${Id}`)
            .then((response) => {
                dispatch({ type: DELETE_HOURS, payload: Id });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "warning",
                        message: "Imputación eliminada.",
                    },
                });
            })
            .catch((err) => err);
    };
};
