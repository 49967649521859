import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_TASKTYPE,
  DELETE_TASKTYPE,
  TASKTYPE_FORM_TOOGLE_LOADING,
  SET_TASKTYPE_LIST,
  SHOW_NOTIFICATION,
  UPDATE_TASKTYPE,
} from "../constants";
import { formatTasktypes } from "./settingsActionsUtils";

/* TASKTYPE LIST */
export const fetchTasktypes = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.tasktypes.list)
      .then((response) => {
        const tasktypes = formatTasktypes(response.data);
        dispatch({
          type: SET_TASKTYPE_LIST,
          payload: keyBy(tasktypes, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// TASKTYPES
export const createTasktype = (newTasktype) => {
  return async (dispatch) => {
    dispatch({ type: TASKTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.tasktypes.create, newTasktype)
      .then((response) => {
        const tasktype = formatTasktypes(response.data);
        dispatch({ type: ADD_NEW_TASKTYPE, payload: tasktype });
        dispatch({ type: TASKTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Tipo de tarea creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TASKTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateTasktype = (updatedTasktype) => {
  return async (dispatch) => {
    dispatch({ type: TASKTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.tasktypes.edit}${updatedTasktype?._id}`, updatedTasktype)
      .then((response) => {
        const tasktype = formatTasktypes(response.data);
        dispatch({ type: UPDATE_TASKTYPE, payload: tasktype });
        dispatch({ type: TASKTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tipo de tarea actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TASKTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteTasktypes = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: TASKTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.tasktypes.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_TASKTYPE, payload: Ids });
        dispatch({ type: TASKTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tipo de tarea eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TASKTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
