import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  equipmentConfigurations,
  equipmentConfigurationsForm,
  instalations,
  instalationsForm,
  projects,
  projectsForm,
  imputHours,
  imputHoursForm,
  singUps,
  singUpsForm,
  equipments,
  equipmentsForm,
  navUsers,
  incidences,
  incidencesForm,
  satisfactions,
  satisfactionsForm,
  alerts,
  alertsForm,
  shopCarts,
  shopCartsForm,
  articles,
  securitySheets,
  securitySheetsForm,
  invoices,
  deliveryNotes,
  downloadDocuments,
  signForm,
  repartidors,
  repartiments,
  suggestions,
  suggestionsForm,
  establishmentInfo,
  establishmentInfoForm,
  tags,
  tagsForm,
  expenses,
  expensesForm,
  OPCUA,
  OPCUAForm,
  timeRequests,
  timeRequestsForm,
  holidayRequests,
  holidayRequestsForm,
  workShifts,
  workShiftsForm,
  dosificationProducts,
  dosificationProductsForm,
  dosificationConfigs,
  dosificationConfigsForm,
  leaves,
  leavesForm,
  reasons,
  reasonsForm,
  refuelings,
  refuelingsForm,
  fertigations,
  fertigationsForm,
  tooltypes,
  tooltypesForm,
  plagues,
  plaguesForm,
  commercialbrands,
  commercialbrandsForm,
  tasktypes,
  tasktypesForm,
  providers,
  providersForm,
  replacements,
  replacementsForm,
  producttypes,
  producttypesForm,
  exploitations,
  exploitationsForm,
  workOrders,
  workOrdersForm,
  bottomFertilizers,
  bottomFertilizersForm,
  phytosanitarys,
  phytosanitarysForm,
  products,
  rolesForm,
  roles,
  plotsForm,
  plots,
  productsForm,
  tools,
  toolsForm,
  seeds,
  seedsForm,
  maintenances,
  maintenancesForm,
  advisersForm,
  advisers,
  tasks,
  tasksForm,
  works,
  worksForm,
  hours,
  hoursForm,
  currentTheme,
  enclosures,
  enclosuresForm,
  crops,
  cropsForm,
  varietys,
  varietysForm,
  familys,
  familysForm,
  epis,
  episForm,
  jobtypes,
  jobtypesForm,
} from "./Reducers";

export default combineReducers({
  jobtypes,
  jobtypesForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  equipmentConfigurations,
  equipmentConfigurationsForm,
  instalations,
  instalationsForm,
  projects,
  projectsForm,
  imputHours,
  imputHoursForm,
  singUps,
  singUpsForm,
  equipments,
  equipmentsForm,
  navUsers,
  incidences,
  incidencesForm,
  satisfactions,
  satisfactionsForm,
  alerts,
  alertsForm,
  shopCarts,
  shopCartsForm,
  articles,
  securitySheets,
  securitySheetsForm,
  invoices,
  deliveryNotes,
  downloadDocuments,
  signForm,
  repartidors,
  repartiments,
  suggestions,
  suggestionsForm,
  establishmentInfo,
  establishmentInfoForm,
  tags,
  tagsForm,
  expenses,
  expensesForm,
  OPCUA,
  OPCUAForm,
  timeRequests,
  timeRequestsForm,
  holidayRequests,
  holidayRequestsForm,
  workShifts,
  workShiftsForm,
  dosificationProducts,
  dosificationProductsForm,
  dosificationConfigs,
  dosificationConfigsForm,
  leaves,
  leavesForm,
  reasons,
  reasonsForm,
  refuelings,
  refuelingsForm,
  fertigations,
  fertigationsForm,
  tooltypes,
  tooltypesForm,
  plagues,
  plaguesForm,
  commercialbrands,
  commercialbrandsForm,
  tasktypes,
  tasktypesForm,
  providers,
  providersForm,
  replacements,
  replacementsForm,
  producttypes,
  producttypesForm,
  exploitations,
  exploitationsForm,
  workOrders,
  workOrdersForm,
  bottomFertilizers,
  bottomFertilizersForm,
  phytosanitarys,
  phytosanitarysForm,
  products,
  rolesForm,
  roles,
  plotsForm,
  plots,
  productsForm,
  tools,
  toolsForm,
  seeds,
  seedsForm,
  maintenances,
  maintenancesForm,
  advisersForm,
  advisers,
  tasks,
  tasksForm,
  works,
  worksForm,
  hours,
  hoursForm,
  enclosures,
  enclosuresForm,
  currentTheme,
  crops,
  cropsForm,
  varietys,
  varietysForm,
  familys,
  familysForm,
  epis,
  episForm,
});
