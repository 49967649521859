import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_CROP,
  DELETE_CROP,
  CROP_FORM_TOOGLE_LOADING,
  SET_CROP_LIST,
  SHOW_NOTIFICATION,
  UPDATE_CROP,
} from "../constants";
import { formatCrops } from "./settingsActionsUtils";

/* CROP LIST */
export const fetchCrops = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.crops.list)
      .then((response) => {
        const crops = formatCrops(response.data);
        dispatch({
          type: SET_CROP_LIST,
          payload: keyBy(crops, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// CROPS
export const createCrop = (newCrop) => {
  return async (dispatch) => {
    dispatch({ type: CROP_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.crops.create, newCrop)
      .then((response) => {
        const crop = formatCrops(response.data);
        dispatch({ type: ADD_NEW_CROP, payload: crop });
        dispatch({ type: CROP_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Cultivo creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: CROP_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateCrop = (updatedCrop) => {
  return async (dispatch) => {
    dispatch({ type: CROP_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.crops.edit}${updatedCrop?._id}`, updatedCrop)
      .then((response) => {
        const crop = formatCrops(response.data);
        dispatch({ type: UPDATE_CROP, payload: crop });
        dispatch({ type: CROP_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Cultivo actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: CROP_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteCrops = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: CROP_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.crops.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_CROP, payload: Ids });
        dispatch({ type: CROP_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Cultivo eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: CROP_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
