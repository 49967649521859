import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_BOTTOMFERTILIZER,
  DELETE_BOTTOMFERTILIZER,
  BOTTOMFERTILIZER_FORM_TOOGLE_LOADING,
  SET_BOTTOMFERTILIZER_LIST,
  SHOW_NOTIFICATION,
  UPDATE_BOTTOMFERTILIZER,
} from "../constants";
import { formatBottomFertilizers } from "./settingsActionsUtils";

/* BOTTOMFERTILIZER LIST */
export const fetchBottomFertilizers = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.bottomfertilizers.list)
      .then((response) => {
        const bottomfertilizers = formatBottomFertilizers(response.data);
        dispatch({
          type: SET_BOTTOMFERTILIZER_LIST,
          payload: keyBy(bottomfertilizers, "value"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// BOTTOMFERTILIZERS
export const createBottomFertilizer = (newBottomFertilizer) => {
  return async (dispatch) => {
    dispatch({ type: BOTTOMFERTILIZER_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.bottomfertilizers.create, newBottomFertilizer)
      .then((response) => {
        const bottomfertilizer = formatBottomFertilizers(response.data);
        dispatch({ type: ADD_NEW_BOTTOMFERTILIZER, payload: bottomfertilizer });
        dispatch({ type: BOTTOMFERTILIZER_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Abono de fondo creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: BOTTOMFERTILIZER_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateBottomFertilizer = (updatedBottomFertilizer) => {
  return async (dispatch) => {
    dispatch({ type: BOTTOMFERTILIZER_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.bottomfertilizers.edit}${updatedBottomFertilizer?._id}`,
        updatedBottomFertilizer
      )
      .then((response) => {
        const bottomfertilizer = formatBottomFertilizers(response.data);
        dispatch({ type: UPDATE_BOTTOMFERTILIZER, payload: bottomfertilizer });
        dispatch({ type: BOTTOMFERTILIZER_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Abono de fondo actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: BOTTOMFERTILIZER_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteBottomFertilizers = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: BOTTOMFERTILIZER_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.bottomfertilizers.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_BOTTOMFERTILIZER, payload: Ids });
        dispatch({ type: BOTTOMFERTILIZER_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Abono de fondo eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: BOTTOMFERTILIZER_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
