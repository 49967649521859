import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_EXPLOITATION,
  DELETE_EXPLOITATION,
  EXPLOITATION_FORM_TOOGLE_LOADING,
  SET_EXPLOITATION_LIST,
  SHOW_NOTIFICATION,
  UPDATE_EXPLOITATION,
} from "../constants";
import { formatExploitations } from "./settingsActionsUtils";

/* EXPLOITATION LIST */
export const fetchExploitations = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.exploitations.list)
      .then((response) => {
        const exploitations = formatExploitations(response.data);
        dispatch({
          type: SET_EXPLOITATION_LIST,
          payload: keyBy(exploitations, "_id"),
        });
        return exploitations;
      })
      .catch((err) => err);
    return response;
  };
};

// EXPLOITATIONS
export const createExploitation = (newExploitation) => {
  return async (dispatch) => {
    dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.exploitations.create, newExploitation)
      .then((response) => {
        const exploitation = formatExploitations(response.data);
        dispatch({ type: ADD_NEW_EXPLOITATION, payload: exploitation });
        dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Explotación creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateExploitation = (updatedExploitation) => {
  return async (dispatch) => {
    dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.exploitations.edit}${updatedExploitation?._id}`, updatedExploitation)
      .then((response) => {
        const exploitation = formatExploitations(response.data);
        dispatch({ type: UPDATE_EXPLOITATION, payload: exploitation });
        dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Explotación actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteExploitations = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.exploitations.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_EXPLOITATION, payload: Ids });
        dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Explotación eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const fetchLocation = (lat, lng) => {
  return async (dispatch) => {
    dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .get(`${API.exploitations.location}/${lat}/${lng}`)
      .then((response) => {
        dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
        return response;
      })
      .catch((err) => {
        dispatch({ type: EXPLOITATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
