import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_ENCLOSURE,
  DELETE_ENCLOSURE,
  ENCLOSURE_FORM_TOOGLE_LOADING,
  SET_ENCLOSURE_LIST,
  SHOW_NOTIFICATION,
  UPDATE_ENCLOSURE,
} from "../constants";
import { formatEnclosures } from "./settingsActionsUtils";

/* ENCLOSURE LIST */
export const fetchEnclosures = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.enclosures.list)
      .then((response) => {
        const enclosures = formatEnclosures(response.data);
        dispatch({
          type: SET_ENCLOSURE_LIST,
          payload: keyBy(enclosures, "_id"),
        });
        return enclosures;
      })
      .catch((err) => err);
    return response;
  };
};

// ENCLOSURES
export const createEnclosure = (newEnclosure) => {
  return async (dispatch) => {
    dispatch({ type: ENCLOSURE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.enclosures.create, newEnclosure)
      .then((response) => {
        const enclosure = formatEnclosures(response.data);
        dispatch({ type: ADD_NEW_ENCLOSURE, payload: enclosure });
        dispatch({ type: ENCLOSURE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Segmento creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: ENCLOSURE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateEnclosure = (updatedEnclosure) => {
  return async (dispatch) => {
    dispatch({ type: ENCLOSURE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.enclosures.edit}${updatedEnclosure?._id}`, updatedEnclosure)
      .then((response) => {
        const enclosure = formatEnclosures(response.data);
        dispatch({ type: UPDATE_ENCLOSURE, payload: enclosure });
        dispatch({ type: ENCLOSURE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Segmento actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: ENCLOSURE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteEnclosures = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: ENCLOSURE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.enclosures.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_ENCLOSURE, payload: Ids });
        dispatch({ type: ENCLOSURE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Segmento eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: ENCLOSURE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
