import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_PROVIDER,
  DELETE_PROVIDER,
  PROVIDER_FORM_TOOGLE_LOADING,
  SET_PROVIDER_LIST,
  SHOW_NOTIFICATION,
  UPDATE_PROVIDER,
} from "../constants";
import { formatProviders } from "./settingsActionsUtils";

/* PROVIDER LIST */
export const fetchProviders = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.providers.list)
      .then((response) => {
        const providers = formatProviders(response.data);
        dispatch({
          type: SET_PROVIDER_LIST,
          payload: keyBy(providers, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// PROVIDERS
export const createProvider = (newProvider) => {
  return async (dispatch) => {
    dispatch({ type: PROVIDER_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.providers.create, newProvider)
      .then((response) => {
        const provider = formatProviders(response.data);
        dispatch({ type: ADD_NEW_PROVIDER, payload: provider });
        dispatch({ type: PROVIDER_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Proveedor creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PROVIDER_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateProvider = (updatedProvider) => {
  return async (dispatch) => {
    dispatch({ type: PROVIDER_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.providers.edit}${updatedProvider?._id}`, updatedProvider)
      .then((response) => {
        const provider = formatProviders(response.data);
        dispatch({ type: UPDATE_PROVIDER, payload: provider });
        dispatch({ type: PROVIDER_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Proveedor actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PROVIDER_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteProviders = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: PROVIDER_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.providers.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_PROVIDER, payload: Ids });
        dispatch({ type: PROVIDER_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Proveedor eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PROVIDER_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
