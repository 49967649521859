import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_INCIDENCE,
  DELETE_INCIDENCE,
  INCIDENCE_FORM_TOOGLE_LOADING,
  SET_INCIDENCE_LIST,
  SHOW_NOTIFICATION,
  UPDATE_INCIDENCE,
} from "../constants";
import { formatIncidences } from "./settingsActionsUtils";

/* INCIDENCE LIST */
export const fetchIncidences = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.incidences.list)
      .then((response) => {
        const incidences = formatIncidences(response.data);
        dispatch({
          type: SET_INCIDENCE_LIST,
          payload: keyBy(incidences, "_id"),
        });
        return keyBy(incidences, "_id");
      })
      .catch((err) => err);
    return response;
  };
};

// INCIDENCES
export const createIncidence = (newIncidence) => {
  return async (dispatch) => {
    dispatch({ type: INCIDENCE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.incidences.create, newIncidence)
      .then((response) => {
        const incidence = formatIncidences(response.data);
        dispatch({ type: ADD_NEW_INCIDENCE, payload: incidence });
        dispatch({ type: INCIDENCE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Incidencia creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: INCIDENCE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateIncidence = (updatedIncidence) => {
  return async (dispatch) => {
    dispatch({ type: INCIDENCE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.incidences.edit}${updatedIncidence?._id}`,
        updatedIncidence
      )
      .then((response) => {
        const incidence = formatIncidences(response.data);
        dispatch({ type: UPDATE_INCIDENCE, payload: incidence });
        dispatch({ type: INCIDENCE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Incidencia actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: INCIDENCE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteIncidences = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: INCIDENCE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.incidences.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_INCIDENCE, payload: Ids });
        dispatch({ type: INCIDENCE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Incidencia eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: INCIDENCE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
