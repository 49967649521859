import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_COMMERCIALBRAND,
  DELETE_COMMERCIALBRAND,
  COMMERCIALBRAND_FORM_TOOGLE_LOADING,
  SET_COMMERCIALBRAND_LIST,
  SHOW_NOTIFICATION,
  UPDATE_COMMERCIALBRAND,
} from "../constants";
import { formatCommercialBrands } from "./settingsActionsUtils";

/* COMMERCIALBRAND LIST */
export const fetchCommercialBrands = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.commercialbrands.list)
      .then((response) => {
        const commercialbrands = formatCommercialBrands(response.data);
        dispatch({
          type: SET_COMMERCIALBRAND_LIST,
          payload: keyBy(commercialbrands, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// COMMERCIALBRANDS
export const createCommercialBrand = (newCommercialBrand) => {
  return async (dispatch) => {
    dispatch({ type: COMMERCIALBRAND_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.commercialbrands.create, newCommercialBrand)
      .then((response) => {
        const commercialbrand = formatCommercialBrands(response.data);
        dispatch({ type: ADD_NEW_COMMERCIALBRAND, payload: commercialbrand });
        dispatch({ type: COMMERCIALBRAND_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Marca comercial creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: COMMERCIALBRAND_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateCommercialBrand = (updatedCommercialBrand) => {
  return async (dispatch) => {
    dispatch({ type: COMMERCIALBRAND_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.commercialbrands.edit}${updatedCommercialBrand?._id}`, updatedCommercialBrand)
      .then((response) => {
        const commercialbrand = formatCommercialBrands(response.data);
        dispatch({ type: UPDATE_COMMERCIALBRAND, payload: commercialbrand });
        dispatch({ type: COMMERCIALBRAND_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Marca comercial actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: COMMERCIALBRAND_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteCommercialBrands = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: COMMERCIALBRAND_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.commercialbrands.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_COMMERCIALBRAND, payload: Ids });
        dispatch({ type: COMMERCIALBRAND_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Marca comercial eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: COMMERCIALBRAND_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
