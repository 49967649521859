import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_TOOLTYPE,
  DELETE_TOOLTYPE,
  TOOLTYPE_FORM_TOOGLE_LOADING,
  SET_TOOLTYPE_LIST,
  SHOW_NOTIFICATION,
  UPDATE_TOOLTYPE,
} from "../constants";
import { formatToolTypes } from "./settingsActionsUtils";

/* TOOLTYPE LIST */
export const fetchToolTypes = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.tooltypes.list)
      .then((response) => {
        const tooltypes = formatToolTypes(response.data);
        dispatch({
          type: SET_TOOLTYPE_LIST,
          payload: keyBy(tooltypes, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// TOOLTYPES
export const createToolType = (newToolType) => {
  return async (dispatch) => {
    dispatch({ type: TOOLTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.tooltypes.create, newToolType)
      .then((response) => {
        const tooltype = formatToolTypes(response.data);
        dispatch({ type: ADD_NEW_TOOLTYPE, payload: tooltype });
        dispatch({ type: TOOLTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Tipo de maquinaria creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TOOLTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateToolType = (updatedToolType) => {
  return async (dispatch) => {
    dispatch({ type: TOOLTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.tooltypes.edit}${updatedToolType?._id}`, updatedToolType)
      .then((response) => {
        const tooltype = formatToolTypes(response.data);
        dispatch({ type: UPDATE_TOOLTYPE, payload: tooltype });
        dispatch({ type: TOOLTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tipo de maquinaria actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TOOLTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteToolTypes = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: TOOLTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.tooltypes.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_TOOLTYPE, payload: Ids });
        dispatch({ type: TOOLTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tipo de maquinaria eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TOOLTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
