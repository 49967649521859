import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_PLAGUE,
  DELETE_PLAGUE,
  PLAGUE_FORM_TOOGLE_LOADING,
  SET_PLAGUE_LIST,
  SHOW_NOTIFICATION,
  UPDATE_PLAGUE,
} from "../constants";
import { formatPlagues } from "./settingsActionsUtils";

/* PLAGUE LIST */
export const fetchPlagues = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.plagues.list)
      .then((response) => {
        const plagues = formatPlagues(response.data);
        dispatch({
          type: SET_PLAGUE_LIST,
          payload: keyBy(plagues, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// PLAGUES
export const createPlague = (newPlague) => {
  return async (dispatch) => {
    dispatch({ type: PLAGUE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.plagues.create, newPlague)
      .then((response) => {
        const plague = formatPlagues(response.data);
        dispatch({ type: ADD_NEW_PLAGUE, payload: plague });
        dispatch({ type: PLAGUE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Tipo de plaga creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PLAGUE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updatePlague = (updatedPlague) => {
  return async (dispatch) => {
    dispatch({ type: PLAGUE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.plagues.edit}${updatedPlague?._id}`, updatedPlague)
      .then((response) => {
        const plague = formatPlagues(response.data);
        dispatch({ type: UPDATE_PLAGUE, payload: plague });
        dispatch({ type: PLAGUE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tipo de plaga actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PLAGUE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deletePlagues = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: PLAGUE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.plagues.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_PLAGUE, payload: Ids });
        dispatch({ type: PLAGUE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tipo de plaga eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PLAGUE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
