import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_SEED,
  DELETE_SEED,
  SEED_FORM_TOOGLE_LOADING,
  SET_SEED_LIST,
  SHOW_NOTIFICATION,
  UPDATE_SEED,
} from "../constants";
import { formatSeeds } from "./settingsActionsUtils";

/* SEED LIST */
export const fetchSeeds = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.seeds.list)
      .then((response) => {
        const seeds = formatSeeds(response.data);
        dispatch({
          type: SET_SEED_LIST,
          payload: keyBy(seeds, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// SEEDS
export const createSeed = (newSeed) => {
  return async (dispatch) => {
    dispatch({ type: SEED_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.seeds.create, newSeed)
      .then((response) => {
        const seed = formatSeeds(response.data);
        dispatch({ type: ADD_NEW_SEED, payload: seed });
        dispatch({ type: SEED_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Semilla creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SEED_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateSeed = (updatedSeed) => {
  return async (dispatch) => {
    dispatch({ type: SEED_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.seeds.edit}${updatedSeed?._id}`, updatedSeed)
      .then((response) => {
        const seed = formatSeeds(response.data);
        dispatch({ type: UPDATE_SEED, payload: seed });
        dispatch({ type: SEED_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Semilla actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SEED_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteSeeds = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: SEED_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.seeds.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_SEED, payload: Ids });
        dispatch({ type: SEED_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Semilla eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: SEED_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
