import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_PRODUCTTYPE,
  DELETE_PRODUCTTYPE,
  PRODUCTTYPE_FORM_TOOGLE_LOADING,
  SET_PRODUCTTYPE_LIST,
  SHOW_NOTIFICATION,
  UPDATE_PRODUCTTYPE,
} from "../constants";
import { formatProductTypes } from "./settingsActionsUtils";

/* PRODUCTTYPE LIST */
export const fetchProductTypes = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.producttypes.list)
      .then((response) => {
        const producttypes = formatProductTypes(response.data);
        dispatch({
          type: SET_PRODUCTTYPE_LIST,
          payload: keyBy(producttypes, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// PRODUCTTYPES
export const createProductType = (newProductType) => {
  return async (dispatch) => {
    dispatch({ type: PRODUCTTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.producttypes.create, newProductType)
      .then((response) => {
        const producttype = formatProductTypes(response.data);
        dispatch({ type: ADD_NEW_PRODUCTTYPE, payload: producttype });
        dispatch({ type: PRODUCTTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Tipo de maquinaria creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PRODUCTTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateProductType = (updatedProductType) => {
  return async (dispatch) => {
    dispatch({ type: PRODUCTTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.producttypes.edit}${updatedProductType?._id}`, updatedProductType)
      .then((response) => {
        const producttype = formatProductTypes(response.data);
        dispatch({ type: UPDATE_PRODUCTTYPE, payload: producttype });
        dispatch({ type: PRODUCTTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tipo de maquinaria actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PRODUCTTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteProductTypes = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: PRODUCTTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.producttypes.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_PRODUCTTYPE, payload: Ids });
        dispatch({ type: PRODUCTTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tipo de maquinaria eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PRODUCTTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
