import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_PHYTOSANITARY,
  DELETE_PHYTOSANITARY,
  PHYTOSANITARY_FORM_TOOGLE_LOADING,
  SET_PHYTOSANITARY_LIST,
  SHOW_NOTIFICATION,
  UPDATE_PHYTOSANITARY,
} from "../constants";
import { formatPhytosanitarys } from "./settingsActionsUtils";

/* PHYTOSANITARY LIST */
export const fetchPhytosanitarys = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.phytosanitarys.list)
      .then((response) => {
        const phytosanitarys = formatPhytosanitarys(response.data);
        dispatch({
          type: SET_PHYTOSANITARY_LIST,
          payload: keyBy(phytosanitarys, "value"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// PHYTOSANITARYS
export const createPhytosanitary = (newPhytosanitary) => {
  return async (dispatch) => {
    dispatch({ type: PHYTOSANITARY_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.phytosanitarys.create, newPhytosanitary)
      .then((response) => {
        const phytosanitary = formatPhytosanitarys(response.data);
        dispatch({ type: ADD_NEW_PHYTOSANITARY, payload: phytosanitary });
        dispatch({ type: PHYTOSANITARY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Fitosanitario creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PHYTOSANITARY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updatePhytosanitary = (updatedPhytosanitary) => {
  return async (dispatch) => {
    dispatch({ type: PHYTOSANITARY_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.phytosanitarys.edit}${updatedPhytosanitary?._id}`,
        updatedPhytosanitary
      )
      .then((response) => {
        const phytosanitary = formatPhytosanitarys(response.data);
        dispatch({ type: UPDATE_PHYTOSANITARY, payload: phytosanitary });
        dispatch({ type: PHYTOSANITARY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Fitosanitario actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PHYTOSANITARY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deletePhytosanitarys = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: PHYTOSANITARY_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.phytosanitarys.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_PHYTOSANITARY, payload: Ids });
        dispatch({ type: PHYTOSANITARY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Fitosanitario eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: PHYTOSANITARY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
