import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_VARIETY,
  DELETE_VARIETY,
  VARIETY_FORM_TOOGLE_LOADING,
  SET_VARIETY_LIST,
  SHOW_NOTIFICATION,
  UPDATE_VARIETY,
} from "../constants";
import { formatVarietys } from "./settingsActionsUtils";

/* VARIETY LIST */
export const fetchVarietys = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.varietys.list)
      .then((response) => {
        const varietys = formatVarietys(response.data);
        dispatch({
          type: SET_VARIETY_LIST,
          payload: keyBy(varietys, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// VARIETYS
export const createVariety = (newVariety) => {
  return async (dispatch) => {
    dispatch({ type: VARIETY_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.varietys.create, newVariety)
      .then((response) => {
        const variety = formatVarietys(response.data);
        dispatch({ type: ADD_NEW_VARIETY, payload: variety });
        dispatch({ type: VARIETY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Variedad creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: VARIETY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateVariety = (updatedVariety) => {
  return async (dispatch) => {
    dispatch({ type: VARIETY_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.varietys.edit}${updatedVariety?._id}`, updatedVariety)
      .then((response) => {
        const variety = formatVarietys(response.data);
        dispatch({ type: UPDATE_VARIETY, payload: variety });
        dispatch({ type: VARIETY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Variedad actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: VARIETY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteVarietys = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: VARIETY_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.varietys.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_VARIETY, payload: Ids });
        dispatch({ type: VARIETY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Variedad eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: VARIETY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
