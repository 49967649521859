import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_TASK,
  DELETE_TASK,
  TASK_FORM_TOOGLE_LOADING,
  SET_TASK_LIST,
  SHOW_NOTIFICATION,
  UPDATE_TASK,
} from "../constants";
import { formatTasks } from "./settingsActionsUtils";

/* TASK LIST */
export const fetchTasks = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.tasks.list)
      .then((response) => {
        const tasks = formatTasks(response.data);
        dispatch({
          type: SET_TASK_LIST,
          payload: keyBy(tasks, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// TASKS
export const createTask = (newTask) => {
  return async (dispatch) => {
    dispatch({ type: TASK_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.tasks.create, newTask)
      .then((response) => {
        const task = formatTasks(response.data);
        dispatch({ type: ADD_NEW_TASK, payload: task });
        dispatch({ type: TASK_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Tarea creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TASK_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateTask = (updatedTask) => {
  return async (dispatch) => {
    dispatch({ type: TASK_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.tasks.edit}${updatedTask?._id}`, updatedTask)
      .then((response) => {
        const task = formatTasks(response.data);
        dispatch({ type: UPDATE_TASK, payload: task });
        dispatch({ type: TASK_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tarea actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TASK_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteTasks = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: TASK_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.tasks.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_TASK, payload: Ids });
        dispatch({ type: TASK_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Tarea eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: TASK_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
