import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_REPLACEMENT,
  DELETE_REPLACEMENT,
  REPLACEMENT_FORM_TOOGLE_LOADING,
  SET_REPLACEMENT_LIST,
  SHOW_NOTIFICATION,
  UPDATE_REPLACEMENT,
} from "../constants";
import { formatReplacements } from "./settingsActionsUtils";

/* REPLACEMENT LIST */
export const fetchReplacements = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.replacements.list)
      .then((response) => {
        const replacements = formatReplacements(response.data);
        dispatch({
          type: SET_REPLACEMENT_LIST,
          payload: keyBy(replacements, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// REPLACEMENTS
export const createReplacement = (newReplacement) => {
  return async (dispatch) => {
    dispatch({ type: REPLACEMENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.replacements.create, newReplacement)
      .then((response) => {
        const replacement = formatReplacements(response.data);
        dispatch({ type: ADD_NEW_REPLACEMENT, payload: replacement });
        dispatch({ type: REPLACEMENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Recambio creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: REPLACEMENT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateReplacement = (updatedReplacement) => {
  return async (dispatch) => {
    dispatch({ type: REPLACEMENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.replacements.edit}${updatedReplacement?._id}`, updatedReplacement)
      .then((response) => {
        const replacement = formatReplacements(response.data);
        dispatch({ type: UPDATE_REPLACEMENT, payload: replacement });
        dispatch({ type: REPLACEMENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Recambio actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: REPLACEMENT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteReplacements = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: REPLACEMENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.replacements.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_REPLACEMENT, payload: Ids });
        dispatch({ type: REPLACEMENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Recambio eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: REPLACEMENT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
