import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_FERTIGATION,
  DELETE_FERTIGATION,
  FERTIGATION_FORM_TOOGLE_LOADING,
  SET_FERTIGATION_LIST,
  SHOW_NOTIFICATION,
  UPDATE_FERTIGATION,
} from "../constants";
import { formatFertigations } from "./settingsActionsUtils";

/* FERTIGATION LIST */
export const fetchFertigations = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.fertigations.list)
      .then((response) => {
        const fertigations = formatFertigations(response.data);
        dispatch({
          type: SET_FERTIGATION_LIST,
          payload: keyBy(fertigations, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// FERTIGATIONS
export const createFertigation = (newFertigation) => {
  return async (dispatch) => {
    dispatch({ type: FERTIGATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.fertigations.create, newFertigation)
      .then((response) => {
        const fertigation = formatFertigations(response.data);
        dispatch({ type: ADD_NEW_FERTIGATION, payload: fertigation });
        dispatch({ type: FERTIGATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Fertirrigación creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: FERTIGATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateFertigation = (updatedFertigation) => {
  return async (dispatch) => {
    dispatch({ type: FERTIGATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.fertigations.edit}${updatedFertigation?._id}`, updatedFertigation)
      .then((response) => {
        const fertigation = formatFertigations(response.data);
        dispatch({ type: UPDATE_FERTIGATION, payload: fertigation });
        dispatch({ type: FERTIGATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Fertirrigación actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: FERTIGATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteFertigations = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: FERTIGATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.fertigations.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_FERTIGATION, payload: Ids });
        dispatch({ type: FERTIGATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Fertirrigación eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: FERTIGATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
