import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  ADD_NEW_EQUIPMENTCONFIGURATION,
  SET_EQUIPMENTCONFIGURATION_LIST,
  UPDATE_EQUIPMENTCONFIGURATION,
  DELETE_EQUIPMENTCONFIGURATION,
  OPEN_EQUIPMENTCONFIGURATION_FORM,
  CLOSE_EQUIPMENTCONFIGURATION_FORM,
  EDIT_SELECTED_EQUIPMENTCONFIGURATION,
  EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING,
  SET_INSTALATION_LIST,
  ADD_NEW_INSTALATION,
  UPDATE_INSTALATION,
  DELETE_INSTALATION,
  OPEN_INSTALATION_FORM,
  CLOSE_INSTALATION_FORM,
  EDIT_SELECTED_INSTALATION,
  INSTALATION_FORM_TOOGLE_LOADING,
  SET_PROJECT_LIST,
  ADD_NEW_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  OPEN_PROJECT_FORM,
  CLOSE_PROJECT_FORM,
  EDIT_SELECTED_PROJECT,
  PROJECT_FORM_TOOGLE_LOADING,
  SET_IMPUTHOUR_LIST,
  ADD_NEW_IMPUTHOUR,
  UPDATE_IMPUTHOUR,
  DELETE_IMPUTHOUR,
  OPEN_IMPUTHOUR_FORM,
  CLOSE_IMPUTHOUR_FORM,
  EDIT_SELECTED_IMPUTHOUR,
  IMPUTHOUR_FORM_TOOGLE_LOADING,
  SET_SINGUP_LIST,
  ADD_NEW_SINGUP,
  UPDATE_SINGUP,
  DELETE_SINGUP,
  OPEN_SINGUP_FORM,
  CLOSE_SINGUP_FORM,
  EDIT_SELECTED_SINGUP,
  SINGUP_FORM_TOOGLE_LOADING,
  SET_EQUIPMENT_LIST,
  ADD_NEW_EQUIPMENT,
  UPDATE_EQUIPMENT,
  DELETE_EQUIPMENT,
  OPEN_EQUIPMENT_FORM,
  CLOSE_EQUIPMENT_FORM,
  EDIT_SELECTED_EQUIPMENT,
  EQUIPMENT_FORM_TOOGLE_LOADING,
  SET_NAVUSER_LIST,
  SET_INCIDENCE_LIST,
  ADD_NEW_INCIDENCE,
  UPDATE_INCIDENCE,
  DELETE_INCIDENCE,
  OPEN_INCIDENCE_FORM,
  CLOSE_INCIDENCE_FORM,
  EDIT_SELECTED_INCIDENCE,
  INCIDENCE_FORM_TOOGLE_LOADING,
  SET_SATISFACTION_LIST,
  ADD_NEW_SATISFACTION,
  UPDATE_SATISFACTION,
  DELETE_SATISFACTION,
  OPEN_SATISFACTION_FORM,
  CLOSE_SATISFACTION_FORM,
  EDIT_SELECTED_SATISFACTION,
  SATISFACTION_FORM_TOOGLE_LOADING,
  ADD_NEW_ALERT,
  SET_ALERT_LIST,
  UPDATE_ALERT,
  DELETE_ALERT,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_SHOPCART_LIST,
  ADD_NEW_SHOPCART,
  UPDATE_SHOPCART,
  DELETE_SHOPCART,
  OPEN_SHOPCART_FORM,
  CLOSE_SHOPCART_FORM,
  EDIT_SELECTED_SHOPCART,
  SHOPCART_FORM_TOOGLE_LOADING,
  SET_ARTICLES_LIST,
  SET_SECURITYSHEET_LIST,
  ADD_NEW_SECURITYSHEET,
  UPDATE_SECURITYSHEET,
  DELETE_SECURITYSHEET,
  OPEN_SECURITYSHEET_FORM,
  CLOSE_SECURITYSHEET_FORM,
  EDIT_SELECTED_SECURITYSHEET,
  SECURITYSHEET_FORM_TOOGLE_LOADING,
  SET_DELIVERYNOTES_LIST,
  SET_INVOICES_LIST,
  SET_DOWNLOADDOCUMENT_LIST,
  OPEN_SIGN_FORM,
  CLOSE_SIGN_FORM,
  SET_REPARTIDORS_LIST,
  SET_REPARTIMENTS_LIST,
  SET_SUGGESTION_LIST,
  ADD_NEW_SUGGESTION,
  UPDATE_SUGGESTION,
  DELETE_SUGGESTION,
  OPEN_SUGGESTION_FORM,
  CLOSE_SUGGESTION_FORM,
  EDIT_SELECTED_SUGGESTION,
  SUGGESTION_FORM_TOOGLE_LOADING,
  SET_ESTABLISHMENTINFO,
  OPEN_ESTABLISHMENTINFO_FORM,
  CLOSE_ESTABLISHMENTINFO_FORM,
  EDIT_SELECTED_ESTABLISHMENTINFO,
  ESTABLISHMENTINFO_FORM_TOOGLE_LOADING,
  SET_TAG_LIST,
  ADD_NEW_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  OPEN_TAG_FORM,
  CLOSE_TAG_FORM,
  EDIT_SELECTED_TAG,
  TAG_FORM_TOOGLE_LOADING,
  SET_EXPENSE_LIST,
  ADD_NEW_EXPENSE,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  OPEN_EXPENSE_FORM,
  CLOSE_EXPENSE_FORM,
  EDIT_SELECTED_EXPENSE,
  EXPENSE_FORM_TOOGLE_LOADING,
  SET_OPCUA_LIST,
  ADD_NEW_OPCUA,
  UPDATE_OPCUA,
  DELETE_OPCUA,
  OPEN_OPCUA_FORM,
  CLOSE_OPCUA_FORM,
  EDIT_SELECTED_OPCUA,
  OPCUA_FORM_TOOGLE_LOADING,
  ADD_NEW_TIMEREQUEST,
  SET_TIMEREQUEST_LIST,
  UPDATE_TIMEREQUEST,
  DELETE_TIMEREQUEST,
  OPEN_TIMEREQUEST_FORM,
  CLOSE_TIMEREQUEST_FORM,
  EDIT_SELECTED_TIMEREQUEST,
  TIMEREQUEST_FORM_TOOGLE_LOADING,
  SET_HOLIDAYREQUEST_LIST,
  ADD_NEW_HOLIDAYREQUEST,
  UPDATE_HOLIDAYREQUEST,
  DELETE_HOLIDAYREQUEST,
  OPEN_HOLIDAYREQUEST_FORM,
  CLOSE_HOLIDAYREQUEST_FORM,
  EDIT_SELECTED_HOLIDAYREQUEST,
  HOLIDAYREQUEST_FORM_TOOGLE_LOADING,
  SET_WORKSHIFT_LIST,
  ADD_NEW_WORKSHIFT,
  UPDATE_WORKSHIFT,
  DELETE_WORKSHIFT,
  OPEN_WORKSHIFT_FORM,
  CLOSE_WORKSHIFT_FORM,
  EDIT_SELECTED_WORKSHIFT,
  WORKSHIFT_FORM_TOOGLE_LOADING,
  SET_DOSIFICATIONPRODUCT_LIST,
  ADD_NEW_DOSIFICATIONPRODUCT,
  UPDATE_DOSIFICATIONPRODUCT,
  DELETE_DOSIFICATIONPRODUCT,
  OPEN_DOSIFICATIONPRODUCT_FORM,
  CLOSE_DOSIFICATIONPRODUCT_FORM,
  EDIT_SELECTED_DOSIFICATIONPRODUCT,
  DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING,
  SET_DOSIFICATIONCONFIG_LIST,
  ADD_NEW_DOSIFICATIONCONFIG,
  UPDATE_DOSIFICATIONCONFIG,
  DELETE_DOSIFICATIONCONFIG,
  OPEN_DOSIFICATIONCONFIG_FORM,
  CLOSE_DOSIFICATIONCONFIG_FORM,
  EDIT_SELECTED_DOSIFICATIONCONFIG,
  DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING,
  SET_LEAVE_LIST,
  ADD_NEW_LEAVE,
  UPDATE_LEAVE,
  DELETE_LEAVE,
  OPEN_LEAVE_FORM,
  CLOSE_LEAVE_FORM,
  EDIT_SELECTED_LEAVE,
  LEAVE_FORM_TOOGLE_LOADING,
  ADD_NEW_REASON,
  SET_REASON_LIST,
  UPDATE_REASON,
  DELETE_REASON,
  OPEN_REASON_FORM,
  CLOSE_REASON_FORM,
  EDIT_SELECTED_REASON,
  REASON_FORM_TOOGLE_LOADING,
  ADD_NEW_FERTIGATION,
  SET_FERTIGATION_LIST,
  UPDATE_FERTIGATION,
  DELETE_FERTIGATION,
  OPEN_FERTIGATION_FORM,
  CLOSE_FERTIGATION_FORM,
  EDIT_SELECTED_FERTIGATION,
  FERTIGATION_FORM_TOOGLE_LOADING,
  ADD_NEW_TOOLTYPE,
  SET_TOOLTYPE_LIST,
  UPDATE_TOOLTYPE,
  DELETE_TOOLTYPE,
  OPEN_TOOLTYPE_FORM,
  CLOSE_TOOLTYPE_FORM,
  EDIT_SELECTED_TOOLTYPE,
  TOOLTYPE_FORM_TOOGLE_LOADING,
  ADD_NEW_PLAGUE,
  SET_PLAGUE_LIST,
  UPDATE_PLAGUE,
  DELETE_PLAGUE,
  OPEN_PLAGUE_FORM,
  CLOSE_PLAGUE_FORM,
  EDIT_SELECTED_PLAGUE,
  PLAGUE_FORM_TOOGLE_LOADING,
  ADD_NEW_COMMERCIALBRAND,
  SET_COMMERCIALBRAND_LIST,
  UPDATE_COMMERCIALBRAND,
  DELETE_COMMERCIALBRAND,
  OPEN_COMMERCIALBRAND_FORM,
  CLOSE_COMMERCIALBRAND_FORM,
  EDIT_SELECTED_COMMERCIALBRAND,
  COMMERCIALBRAND_FORM_TOOGLE_LOADING,
  ADD_NEW_TASKTYPE,
  SET_TASKTYPE_LIST,
  UPDATE_TASKTYPE,
  DELETE_TASKTYPE,
  OPEN_TASKTYPE_FORM,
  CLOSE_TASKTYPE_FORM,
  EDIT_SELECTED_TASKTYPE,
  TASKTYPE_FORM_TOOGLE_LOADING,
  ADD_NEW_PROVIDER,
  SET_PROVIDER_LIST,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
  OPEN_PROVIDER_FORM,
  CLOSE_PROVIDER_FORM,
  EDIT_SELECTED_PROVIDER,
  PROVIDER_FORM_TOOGLE_LOADING,
  ADD_NEW_REPLACEMENT,
  SET_REPLACEMENT_LIST,
  UPDATE_REPLACEMENT,
  DELETE_REPLACEMENT,
  OPEN_REPLACEMENT_FORM,
  CLOSE_REPLACEMENT_FORM,
  EDIT_SELECTED_REPLACEMENT,
  REPLACEMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_PRODUCTTYPE,
  SET_PRODUCTTYPE_LIST,
  UPDATE_PRODUCTTYPE,
  DELETE_PRODUCTTYPE,
  OPEN_PRODUCTTYPE_FORM,
  CLOSE_PRODUCTTYPE_FORM,
  EDIT_SELECTED_PRODUCTTYPE,
  PRODUCTTYPE_FORM_TOOGLE_LOADING,
  ADD_NEW_FAMILY,
  SET_FAMILY_LIST,
  UPDATE_FAMILY,
  DELETE_FAMILY,
  OPEN_FAMILY_FORM,
  CLOSE_FAMILY_FORM,
  EDIT_SELECTED_FAMILY,
  FAMILY_FORM_TOOGLE_LOADING,
  ADD_NEW_VARIETY,
  SET_VARIETY_LIST,
  UPDATE_VARIETY,
  DELETE_VARIETY,
  OPEN_VARIETY_FORM,
  CLOSE_VARIETY_FORM,
  EDIT_SELECTED_VARIETY,
  VARIETY_FORM_TOOGLE_LOADING,
  ADD_NEW_CROP,
  SET_CROP_LIST,
  UPDATE_CROP,
  DELETE_CROP,
  OPEN_CROP_FORM,
  CLOSE_CROP_FORM,
  EDIT_SELECTED_CROP,
  CROP_FORM_TOOGLE_LOADING,
  SET_WORKORDER_LIST,
  ADD_NEW_WORKORDER,
  UPDATE_WORKORDER,
  DELETE_WORKORDER,
  OPEN_WORKORDER_FORM,
  CLOSE_WORKORDER_FORM,
  EDIT_SELECTED_WORKORDER,
  WORKORDER_FORM_TOOGLE_LOADING,
  SET_EPI_LIST,
  ADD_NEW_EPI,
  UPDATE_EPI,
  DELETE_EPI,
  OPEN_EPI_FORM,
  CLOSE_EPI_FORM,
  EDIT_SELECTED_EPI,
  EPI_FORM_TOOGLE_LOADING,
  SET_BOTTOMFERTILIZER_LIST,
  ADD_NEW_BOTTOMFERTILIZER,
  UPDATE_BOTTOMFERTILIZER,
  DELETE_BOTTOMFERTILIZER,
  OPEN_BOTTOMFERTILIZER_FORM,
  CLOSE_BOTTOMFERTILIZER_FORM,
  EDIT_SELECTED_BOTTOMFERTILIZER,
  BOTTOMFERTILIZER_FORM_TOOGLE_LOADING,
  SET_PHYTOSANITARY_LIST,
  ADD_NEW_PHYTOSANITARY,
  UPDATE_PHYTOSANITARY,
  DELETE_PHYTOSANITARY,
  OPEN_PHYTOSANITARY_FORM,
  CLOSE_PHYTOSANITARY_FORM,
  EDIT_SELECTED_PHYTOSANITARY,
  PHYTOSANITARY_FORM_TOOGLE_LOADING,
  SET_ROLE_LIST,
  ADD_NEW_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  OPEN_ROLE_FORM,
  CLOSE_ROLE_FORM,
  EDIT_SELECTED_ROLE,
  ROLE_FORM_TOOGLE_LOADING,
  SET_EXPLOITATION_LIST,
  ADD_NEW_EXPLOITATION,
  UPDATE_EXPLOITATION,
  DELETE_EXPLOITATION,
  OPEN_EXPLOITATION_FORM,
  CLOSE_EXPLOITATION_FORM,
  EDIT_SELECTED_EXPLOITATION,
  EXPLOITATION_FORM_TOOGLE_LOADING,
  SET_PLOT_LIST,
  ADD_NEW_PLOT,
  UPDATE_PLOT,
  DELETE_PLOT,
  OPEN_PLOT_FORM,
  CLOSE_PLOT_FORM,
  EDIT_SELECTED_PLOT,
  PLOT_FORM_TOOGLE_LOADING,
  OPEN_PRODUCT_FORM,
  CLOSE_PRODUCT_FORM,
  EDIT_SELECTED_PRODUCT,
  PRODUCT_FORM_TOOGLE_LOADING,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  ADD_NEW_PRODUCT,
  SET_PRODUCT_LIST,
  DELETE_TOOL,
  UPDATE_TOOL,
  ADD_NEW_TOOL,
  SET_TOOL_LIST,
  OPEN_TOOL_FORM,
  CLOSE_TOOL_FORM,
  EDIT_SELECTED_TOOL,
  TOOL_FORM_TOOGLE_LOADING,
  SET_SEED_LIST,
  ADD_NEW_SEED,
  UPDATE_SEED,
  DELETE_SEED,
  OPEN_SEED_FORM,
  CLOSE_SEED_FORM,
  EDIT_SELECTED_SEED,
  SEED_FORM_TOOGLE_LOADING,
  MAINTENANCE_FORM_TOOGLE_LOADING,
  EDIT_SELECTED_MAINTENANCE,
  CLOSE_MAINTENANCE_FORM,
  OPEN_MAINTENANCE_FORM,
  UPDATE_MAINTENANCE,
  DELETE_MAINTENANCE,
  SET_MAINTENANCE_LIST,
  ADD_NEW_MAINTENANCE,
  SET_ADVISER_LIST,
  ADD_NEW_ADVISER,
  UPDATE_ADVISER,
  DELETE_ADVISER,
  OPEN_ADVISER_FORM,
  CLOSE_ADVISER_FORM,
  EDIT_SELECTED_ADVISER,
  ADVISER_FORM_TOOGLE_LOADING,
  SET_TASK_LIST,
  ADD_NEW_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  OPEN_TASK_FORM,
  CLOSE_TASK_FORM,
  EDIT_SELECTED_TASK,
  TASK_FORM_TOOGLE_LOADING,
  SET_WORK_LIST,
  ADD_NEW_WORK,
  UPDATE_WORK,
  DELETE_WORK,
  OPEN_WORK_FORM,
  CLOSE_WORK_FORM,
  EDIT_SELECTED_WORK,
  WORK_FORM_TOOGLE_LOADING,
  DELETE_HOURS,
  UPDATE_HOURS,
  SET_HOURS,
  ADD_NEW_HOURS,
  HOURS_FORM_TOOGLE_LOADING,
  CLOSE_VIEW_HOURS,
  VIEW_HOURS,
  EDIT_SELECTED_HOURS,
  CLOSE_HOURS_FORM,
  OPEN_HOURS_FORM,
  THEME,
  SET_ALLINSTALATION_LIST,
  SET_ALLEQUIPMENT_LIST,
  SET_ENCLOSURE_LIST,
  ADD_NEW_ENCLOSURE,
  UPDATE_ENCLOSURE,
  DELETE_ENCLOSURE,
  OPEN_ENCLOSURE_FORM,
  CLOSE_ENCLOSURE_FORM,
  EDIT_SELECTED_ENCLOSURE,
  ENCLOSURE_FORM_TOOGLE_LOADING,
  SET_REFUELING_LIST,
  ADD_NEW_REFUELING,
  UPDATE_REFUELING,
  DELETE_REFUELING,
  OPEN_REFUELING_FORM,
  CLOSE_REFUELING_FORM,
  EDIT_SELECTED_REFUELING,
  REFUELING_FORM_TOOGLE_LOADING,
  ADD_NEW_JOBTYPE,
  SET_JOBTYPE_LIST,
  UPDATE_JOBTYPE,
  DELETE_JOBTYPE,
  OPEN_JOBTYPE_FORM,
  CLOSE_JOBTYPE_FORM,
  EDIT_SELECTED_JOBTYPE,
  JOBTYPE_FORM_TOOGLE_LOADING,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  null
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleActions(
  {
    [THEME]: (state, action) => action.payload,
  },
  true
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const navUsers = handleActions(
  {
    [SET_NAVUSER_LIST]: (state, action) => action.payload,
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EQUIPMENTCONFIGURATIONS
export const equipmentConfigurations = handleActions(
  {
    [SET_EQUIPMENTCONFIGURATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_EQUIPMENTCONFIGURATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EQUIPMENTCONFIGURATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EQUIPMENTCONFIGURATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const equipmentConfigurationsForm = handleActions(
  {
    [OPEN_EQUIPMENTCONFIGURATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EQUIPMENTCONFIGURATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EQUIPMENTCONFIGURATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// DOWNLOADDOCUMENT
export const downloadDocuments = handleActions(
  {
    [SET_DOWNLOADDOCUMENT_LIST]: (state, action) => action.payload,
  },
  null
);

// INSTALATIONS
export const instalations = handleActions(
  {
    [SET_INSTALATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_INSTALATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_INSTALATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_INSTALATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const instalationsForm = handleActions(
  {
    [OPEN_INSTALATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_INSTALATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_INSTALATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [INSTALATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const signForm = handleActions(
  {
    [OPEN_SIGN_FORM]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CLOSE_SIGN_FORM]: (s, action) => ({
      show: false,
      data: null,
      loading: false,
    }),
  },
  { show: false, data: null, loading: false }
);

// SECURITYSHEETS
export const securitySheets = handleActions(
  {
    [SET_SECURITYSHEET_LIST]: (state, action) => action.payload,
    [ADD_NEW_SECURITYSHEET]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SECURITYSHEET]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SECURITYSHEET]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const securitySheetsForm = handleActions(
  {
    [OPEN_SECURITYSHEET_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SECURITYSHEET_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SECURITYSHEET]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SECURITYSHEET_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ARTICLES
export const articles = handleActions(
  {
    [SET_ARTICLES_LIST]: (state, action) => action.payload,
  },
  null
);

// REPARTIDORS
export const repartidors = handleActions(
  {
    [SET_REPARTIDORS_LIST]: (state, action) => action.payload,
  },
  null
);

// REPARTIMENTS
export const repartiments = handleActions(
  {
    [SET_REPARTIMENTS_LIST]: (state, action) => action.payload,
  },
  null
);

// INVOICES
export const invoices = handleActions(
  {
    [SET_INVOICES_LIST]: (state, action) => action.payload,
  },
  null
);

// DELIVERYNOTES
export const deliveryNotes = handleActions(
  {
    [SET_DELIVERYNOTES_LIST]: (state, action) => action.payload,
  },
  null
);

export const allInstalations = handleActions(
  {
    [SET_ALLINSTALATION_LIST]: (state, action) => action.payload,
  },
  null
);

export const allEquipments = handleActions(
  {
    [SET_ALLEQUIPMENT_LIST]: (state, action) => action.payload,
  },
  null
);

// SHOPCARTS
export const shopCarts = handleActions(
  {
    [SET_SHOPCART_LIST]: (state, action) => action.payload,
    [ADD_NEW_SHOPCART]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SHOPCART]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SHOPCART]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const shopCartsForm = handleActions(
  {
    [OPEN_SHOPCART_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SHOPCART_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SHOPCART]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SHOPCART_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SATISFACTIONS
export const satisfactions = handleActions(
  {
    [SET_SATISFACTION_LIST]: (state, action) => action.payload,
    [ADD_NEW_SATISFACTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SATISFACTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SATISFACTION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const satisfactionsForm = handleActions(
  {
    [OPEN_SATISFACTION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SATISFACTION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SATISFACTION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SATISFACTION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// INCIDENCES
export const incidences = handleActions(
  {
    [SET_INCIDENCE_LIST]: (state, action) => action.payload,
    [ADD_NEW_INCIDENCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_INCIDENCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_INCIDENCE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const incidencesForm = handleActions(
  {
    [OPEN_INCIDENCE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_INCIDENCE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_INCIDENCE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [INCIDENCE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EQUIPMENTS
export const equipments = handleActions(
  {
    [SET_EQUIPMENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_EQUIPMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EQUIPMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EQUIPMENT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const equipmentsForm = handleActions(
  {
    [OPEN_EQUIPMENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EQUIPMENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EQUIPMENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EQUIPMENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SINGUPS
export const singUps = handleActions(
  {
    [SET_SINGUP_LIST]: (state, action) => action.payload,
    [ADD_NEW_SINGUP]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SINGUP]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SINGUP]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const singUpsForm = handleActions(
  {
    [OPEN_SINGUP_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SINGUP_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SINGUP]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SINGUP_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PROJECTS
export const projects = handleActions(
  {
    [SET_PROJECT_LIST]: (state, action) => action.payload,
    [ADD_NEW_PROJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PROJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PROJECT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const projectsForm = handleActions(
  {
    [OPEN_PROJECT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PROJECT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PROJECT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PROJECT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SUGGESTIONS
export const suggestions = handleActions(
  {
    [SET_SUGGESTION_LIST]: (state, action) => action.payload,
    [ADD_NEW_SUGGESTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SUGGESTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SUGGESTION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const suggestionsForm = handleActions(
  {
    [OPEN_SUGGESTION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SUGGESTION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SUGGESTION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SUGGESTION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// WORKORDERS
export const workOrders = handleActions(
  {
    [SET_WORKORDER_LIST]: (state, action) => action.payload,
    [ADD_NEW_WORKORDER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_WORKORDER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_WORKORDER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const workOrdersForm = handleActions(
  {
    [OPEN_WORKORDER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_WORKORDER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_WORKORDER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [WORKORDER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EPIS
export const epis = handleActions(
  {
    [SET_EPI_LIST]: (state, action) => action.payload,
    [ADD_NEW_EPI]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EPI]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EPI]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const episForm = handleActions(
  {
    [OPEN_EPI_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EPI_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EPI]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EPI_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// BOTTOMFERTILIZERS
export const bottomFertilizers = handleActions(
  {
    [SET_BOTTOMFERTILIZER_LIST]: (state, action) => action.payload,
    [ADD_NEW_BOTTOMFERTILIZER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_BOTTOMFERTILIZER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_BOTTOMFERTILIZER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const bottomFertilizersForm = handleActions(
  {
    [OPEN_BOTTOMFERTILIZER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_BOTTOMFERTILIZER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_BOTTOMFERTILIZER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [BOTTOMFERTILIZER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PHYTOSANITARYS
export const phytosanitarys = handleActions(
  {
    [SET_PHYTOSANITARY_LIST]: (state, action) => action.payload,
    [ADD_NEW_PHYTOSANITARY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PHYTOSANITARY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PHYTOSANITARY]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const phytosanitarysForm = handleActions(
  {
    [OPEN_PHYTOSANITARY_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PHYTOSANITARY_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PHYTOSANITARY]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PHYTOSANITARY_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TAGS
export const tags = handleActions(
  {
    [SET_TAG_LIST]: (state, action) => action.payload,
    [ADD_NEW_TAG]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TAG]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TAG]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const tagsForm = handleActions(
  {
    [OPEN_TAG_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TAG_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TAG]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TAG_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// REASONS
export const reasons = handleActions(
  {
    [SET_REASON_LIST]: (state, action) => action.payload,
    [ADD_NEW_REASON]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_REASON]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_REASON]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const reasonsForm = handleActions(
  {
    [OPEN_REASON_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_REASON_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_REASON]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [REASON_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// REFUELING
export const refuelings = handleActions(
  {
    [SET_REFUELING_LIST]: (state, action) => action.payload,
    [ADD_NEW_REFUELING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_REFUELING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_REFUELING]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const refuelingsForm = handleActions(
  {
    [OPEN_REFUELING_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_REFUELING_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_REFUELING]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [REFUELING_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// FERTIGATIONS
export const fertigations = handleActions(
  {
    [SET_FERTIGATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_FERTIGATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_FERTIGATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_FERTIGATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const fertigationsForm = handleActions(
  {
    [OPEN_FERTIGATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_FERTIGATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_FERTIGATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [FERTIGATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TOOLTYPES
export const tooltypes = handleActions(
  {
    [SET_TOOLTYPE_LIST]: (state, action) => action.payload,
    [ADD_NEW_TOOLTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TOOLTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TOOLTYPE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const tooltypesForm = handleActions(
  {
    [OPEN_TOOLTYPE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TOOLTYPE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TOOLTYPE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TOOLTYPE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PLAGUES
export const plagues = handleActions(
  {
    [SET_PLAGUE_LIST]: (state, action) => action.payload,
    [ADD_NEW_PLAGUE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PLAGUE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PLAGUE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const plaguesForm = handleActions(
  {
    [OPEN_PLAGUE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PLAGUE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PLAGUE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PLAGUE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// COMMERCIALBRANDS
export const commercialbrands = handleActions(
  {
    [SET_COMMERCIALBRAND_LIST]: (state, action) => action.payload,
    [ADD_NEW_COMMERCIALBRAND]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_COMMERCIALBRAND]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_COMMERCIALBRAND]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const commercialbrandsForm = handleActions(
  {
    [OPEN_COMMERCIALBRAND_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_COMMERCIALBRAND_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_COMMERCIALBRAND]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [COMMERCIALBRAND_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TASKTYPES
export const tasktypes = handleActions(
  {
    [SET_TASKTYPE_LIST]: (state, action) => action.payload,
    [ADD_NEW_TASKTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TASKTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TASKTYPE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const tasktypesForm = handleActions(
  {
    [OPEN_TASKTYPE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TASKTYPE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TASKTYPE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TASKTYPE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PROVIDERS
export const providers = handleActions(
  {
    [SET_PROVIDER_LIST]: (state, action) => action.payload,
    [ADD_NEW_PROVIDER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PROVIDER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PROVIDER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const providersForm = handleActions(
  {
    [OPEN_PROVIDER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PROVIDER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PROVIDER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PROVIDER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);
// REPLACEMENTS
export const replacements = handleActions(
  {
    [SET_REPLACEMENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_REPLACEMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_REPLACEMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_REPLACEMENT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const replacementsForm = handleActions(
  {
    [OPEN_REPLACEMENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_REPLACEMENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_REPLACEMENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [REPLACEMENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PRODUCTTYPES
export const producttypes = handleActions(
  {
    [SET_PRODUCTTYPE_LIST]: (state, action) => action.payload,
    [ADD_NEW_PRODUCTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PRODUCTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PRODUCTTYPE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const producttypesForm = handleActions(
  {
    [OPEN_PRODUCTTYPE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PRODUCTTYPE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PRODUCTTYPE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PRODUCTTYPE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// FAMILYS
export const familys = handleActions(
  {
    [SET_FAMILY_LIST]: (state, action) => action.payload,
    [ADD_NEW_FAMILY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_FAMILY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_FAMILY]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const familysForm = handleActions(
  {
    [OPEN_FAMILY_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_FAMILY_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_FAMILY]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [FAMILY_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// VARIETYS
export const varietys = handleActions(
  {
    [SET_VARIETY_LIST]: (state, action) => action.payload,
    [ADD_NEW_VARIETY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_VARIETY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_VARIETY]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const varietysForm = handleActions(
  {
    [OPEN_VARIETY_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_VARIETY_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_VARIETY]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [VARIETY_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CROPS
export const crops = handleActions(
  {
    [SET_CROP_LIST]: (state, action) => action.payload,
    [ADD_NEW_CROP]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CROP]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CROP]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const cropsForm = handleActions(
  {
    [OPEN_CROP_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CROP_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CROP]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CROP_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// WORKS
export const works = handleActions(
  {
    [SET_WORK_LIST]: (state, action) => action.payload,
    [ADD_NEW_WORK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_WORK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_WORK]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const worksForm = handleActions(
  {
    [OPEN_WORK_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_WORK_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_WORK]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [WORK_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TASKS
export const tasks = handleActions(
  {
    [SET_TASK_LIST]: (state, action) => action.payload,
    [ADD_NEW_TASK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TASK]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TASK]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const tasksForm = handleActions(
  {
    [OPEN_TASK_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TASK_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TASK]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TASK_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ADVISERS
export const advisers = handleActions(
  {
    [SET_ADVISER_LIST]: (state, action) => action.payload,
    [ADD_NEW_ADVISER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ADVISER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ADVISER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const advisersForm = handleActions(
  {
    [OPEN_ADVISER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ADVISER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ADVISER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ADVISER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SEEDS
export const seeds = handleActions(
  {
    [SET_SEED_LIST]: (state, action) => action.payload,
    [ADD_NEW_SEED]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SEED]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SEED]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const seedsForm = handleActions(
  {
    [OPEN_SEED_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SEED_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SEED]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SEED_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TOOLS
export const tools = handleActions(
  {
    [SET_TOOL_LIST]: (state, action) => action.payload,
    [ADD_NEW_TOOL]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TOOL]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TOOL]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const toolsForm = handleActions(
  {
    [OPEN_TOOL_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TOOL_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TOOL]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TOOL_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PRODUCTS
export const products = handleActions(
  {
    [SET_PRODUCT_LIST]: (state, action) => action.payload,
    [ADD_NEW_PRODUCT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PRODUCT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PRODUCT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const productsForm = handleActions(
  {
    [OPEN_PRODUCT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PRODUCT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PRODUCT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PRODUCT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EXPLOITATIONS
export const exploitations = handleActions(
  {
    [SET_EXPLOITATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_EXPLOITATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EXPLOITATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EXPLOITATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const exploitationsForm = handleActions(
  {
    [OPEN_EXPLOITATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EXPLOITATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EXPLOITATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EXPLOITATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PLOTS
export const plots = handleActions(
  {
    [SET_PLOT_LIST]: (state, action) => action.payload,
    [ADD_NEW_PLOT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PLOT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PLOT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const plotsForm = handleActions(
  {
    [OPEN_PLOT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PLOT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PLOT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PLOT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ENCLOSURES
export const enclosures = handleActions(
  {
    [SET_ENCLOSURE_LIST]: (state, action) => action.payload,
    [ADD_NEW_ENCLOSURE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ENCLOSURE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ENCLOSURE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const enclosuresForm = handleActions(
  {
    [OPEN_ENCLOSURE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ENCLOSURE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ENCLOSURE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ENCLOSURE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ROLES
export const roles = handleActions(
  {
    [SET_ROLE_LIST]: (state, action) => action.payload,
    [ADD_NEW_ROLE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ROLE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ROLE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const rolesForm = handleActions(
  {
    [OPEN_ROLE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ROLE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ROLE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ROLE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// WORKSHIFTS
export const workShifts = handleActions(
  {
    [SET_WORKSHIFT_LIST]: (state, action) => action.payload,
    [ADD_NEW_WORKSHIFT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_WORKSHIFT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_WORKSHIFT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const workShiftsForm = handleActions(
  {
    [OPEN_WORKSHIFT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_WORKSHIFT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_WORKSHIFT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [WORKSHIFT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// LEAVES
export const leaves = handleActions(
  {
    [SET_LEAVE_LIST]: (state, action) => action.payload,
    [ADD_NEW_LEAVE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_LEAVE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_LEAVE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const leavesForm = handleActions(
  {
    [OPEN_LEAVE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_LEAVE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_LEAVE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [LEAVE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// DOSIFICATIONPRODUCTS
export const dosificationProducts = handleActions(
  {
    [SET_DOSIFICATIONPRODUCT_LIST]: (state, action) => action.payload,
    [ADD_NEW_DOSIFICATIONPRODUCT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_DOSIFICATIONPRODUCT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_DOSIFICATIONPRODUCT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const dosificationProductsForm = handleActions(
  {
    [OPEN_DOSIFICATIONPRODUCT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_DOSIFICATIONPRODUCT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_DOSIFICATIONPRODUCT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// DOSIFICATIONCONFIGS
export const dosificationConfigs = handleActions(
  {
    [SET_DOSIFICATIONCONFIG_LIST]: (state, action) => action.payload,
    [ADD_NEW_DOSIFICATIONCONFIG]: (state, action) => action.payload,
    [UPDATE_DOSIFICATIONCONFIG]: (state, action) => action.payload,
    [DELETE_DOSIFICATIONCONFIG]: (state, action) => action.payload,
  },
  null
);

export const dosificationConfigsForm = handleActions(
  {
    [OPEN_DOSIFICATIONCONFIG_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_DOSIFICATIONCONFIG_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_DOSIFICATIONCONFIG]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// OPCUA
export const OPCUA = handleActions(
  {
    [SET_OPCUA_LIST]: (state, action) => action.payload,
    [ADD_NEW_OPCUA]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_OPCUA]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_OPCUA]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const OPCUAForm = handleActions(
  {
    [OPEN_OPCUA_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_OPCUA_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_OPCUA]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [OPCUA_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EXPENSES
export const expenses = handleActions(
  {
    [SET_EXPENSE_LIST]: (state, action) => action.payload,
    [ADD_NEW_EXPENSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EXPENSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EXPENSE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const expensesForm = handleActions(
  {
    [OPEN_EXPENSE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EXPENSE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EXPENSE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EXPENSE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ESTABLISHMENTINFOS
export const establishmentInfo = handleActions(
  {
    [SET_ESTABLISHMENTINFO]: (state, action) => action.payload,
  },
  null
);

export const establishmentInfoForm = handleActions(
  {
    [OPEN_ESTABLISHMENTINFO_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ESTABLISHMENTINFO_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ESTABLISHMENTINFO]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ESTABLISHMENTINFO_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// IMPUTHOURS
export const imputHours = handleActions(
  {
    [SET_IMPUTHOUR_LIST]: (state, action) => action.payload,
    [ADD_NEW_IMPUTHOUR]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_IMPUTHOUR]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_IMPUTHOUR]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const imputHoursForm = handleActions(
  {
    [OPEN_IMPUTHOUR_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_IMPUTHOUR_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_IMPUTHOUR]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [IMPUTHOUR_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TIMEREQUESTS
export const timeRequests = handleActions(
  {
    [SET_TIMEREQUEST_LIST]: (state, action) => action.payload,
    [ADD_NEW_TIMEREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TIMEREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TIMEREQUEST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const timeRequestsForm = handleActions(
  {
    [OPEN_TIMEREQUEST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TIMEREQUEST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TIMEREQUEST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TIMEREQUEST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// HOLIDAYREQUESTS
export const holidayRequests = handleActions(
  {
    [SET_HOLIDAYREQUEST_LIST]: (state, action) => action.payload,
    [ADD_NEW_HOLIDAYREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_HOLIDAYREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_HOLIDAYREQUEST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const holidayRequestsForm = handleActions(
  {
    [OPEN_HOLIDAYREQUEST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_HOLIDAYREQUEST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_HOLIDAYREQUEST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [HOLIDAYREQUEST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// MAINTENANCES
export const maintenances = handleActions(
  {
    [SET_MAINTENANCE_LIST]: (state, action) => action.payload,
    [ADD_NEW_MAINTENANCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_MAINTENANCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_MAINTENANCE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const maintenancesForm = handleActions(
  {
    [OPEN_MAINTENANCE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_MAINTENANCE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_MAINTENANCE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [MAINTENANCE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const hoursForm = handleActions(
  {
    [OPEN_HOURS_FORM]: (state, action) => ({
      ...state,
      show: !state.show,
      data: action.payload,
      loading: false,
    }),
    [CLOSE_HOURS_FORM]: (state) => ({
      ...state,
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_HOURS]: (s, action) => ({
      ...s,
      show: true,
      data: action.payload,
      loading: false,
    }),
    [VIEW_HOURS]: (s, action) => ({
      ...s,
      showList: true,
      _id: action.payload,
      loading: false,
    }),
    [CLOSE_VIEW_HOURS]: (s, action) => ({
      ...s,
      showList: false,
      data: action.payload,
      loading: false,
    }),
    [HOURS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false, showList: false, _id: null }
);

export const hours = handleActions(
  {
    [SET_HOURS]: (state, action) => action.payload,
    [ADD_NEW_HOURS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_HOURS]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_HOURS]: (state, action) => {
      delete state[action.payload];
      return { ...state };
    },
  },
  null
);
// JOBTYPES
export const jobtypes = handleActions(
    {
        [SET_JOBTYPE_LIST]: (state, action) => action.payload,
        [ADD_NEW_JOBTYPE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_JOBTYPE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_JOBTYPE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const jobtypesForm = handleActions(
    {
        [OPEN_JOBTYPE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_JOBTYPE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_JOBTYPE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [JOBTYPE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);
